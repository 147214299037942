<template>
  <a-modal
    :title="'修改发布渠道'"
    :maskClosable="false"
    v-model="visible"
    width="360px"
    :confirmLoading="saveLoading"
    @ok="save"
    @cancel="onClose"
  >
    <a-select style="width: 100%" v-model="form.publishChannel" placeholder="请选择发布渠道">
      <a-select-option v-for="(v, i) in channelList" :key="i" :value="v.value">{{ v.label }}</a-select-option>
    </a-select>
  </a-modal>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  data() {
    return {
      visible: false,
      saveLoading: false,
      channelList: [],
      form: {
        noteId: undefined,
        publishChannel: undefined,
      },
    };
  },
  methods: {
    /**
     * 打开弹窗
     */
    openModal(channelList, data) {
      Object.assign(this.$data, this.$options.data());
      this.channelList = channelList.filter((item) => item.value !== 'NO_OPERATIONAL');
      Object.assign(this.form, data);
      this.form.publishChannel = this.channelList[0].value;
      this.visible = true;
    },
    /**
     * 关闭弹窗
     */
    onClose() {
      this.visible = false;
    },
    /**
     * 推送图片
     */
    async save() {
      try {
        this.saveLoading = true;
        const { code, message } = await api.updatePublishChannel(this.form);
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success(`修改成功`);
          this.$emit('success');
          this.onClose();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.saveLoading = false;
        console.log(error);
      }
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
