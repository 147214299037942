<template>
  <div class="xhsNoteAnalysis">
    <a-back-top :visibilityHeight="200" style="right: 10px" />
    <div style="position: relative">
      <a-alert message="每日采集运营账号的全部笔记数据，包含非代运营的笔记（门店自己在小红书发的笔记）。" banner />
      <a-tooltip placement="bottom">
        <template slot="title">
          <p>每天15:30发起数据采集，采集期间需保持授权有效</p>
          <p>互动量：点赞、收藏、评论的总和</p>
          <p>添加时间：添加这条笔记入库的时间</p>
          <div style="margin: 10px 0">
            <div>发布渠道：</div>
            <div>百夫长：运营通过百夫长直接发布笔记</div>
            <div>风火轮：推送笔记内容后，在风火轮内点击发布按钮进行发布</div>
            <div>分享链接：推送笔记内容后，通过分享链接进行发布</div>
            <div>其他：销售在风火轮保存素材到本地去发布，或者运营在百夫长从“待发布”手动设置成“已发布”</div>
            <div>非运营笔记：运营没有推送内容，客户自行在小红书发笔记。</div>
          </div>
          <p>笔记权限：该笔记在小红书内的权限设置</p>
        </template>
        <div style="margin-right: 20px; cursor: pointer; position: absolute; top: 8px; right: 0">
          <a-icon type="question-circle" style="margin-right: 10px" />数据说明
        </div>
      </a-tooltip>
    </div>
    <div style="display: flex; justify-content: space-between; margin: 10px 0">
      <a-select
        style="width: 200px"
        v-model="searchForm.principalId"
        option-filter-prop="children"
        show-search
        placeholder="请选择品牌"
        allowClear
        @change="handleSearchPrincipalChange"
      >
        <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">{{
          item.label
        }}</a-select-option>
      </a-select>

      <a-select
        style="width: 200px"
        :loading="seriesLoading"
        v-model="searchForm.productId"
        placeholder="请选择车系"
        allowClear
        show-search
        :filter-option="false"
        @change="getDataList(true)"
      >
        <a-select-option v-for="item in seriesList" :key="item.id">{{ item.name }}</a-select-option>
      </a-select>
      <TopicTreeInput
        show-search
        style="width: 200px"
        v-model="searchForm.tagIdList"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :replaceFields="{ title: 'name' }"
        placeholder="请选择选题"
        multiple
        :maxTagCount="1"
        allow-clear
        tree-default-expand-all
        @change="handleTagChange"
      />
      <a-select
        style="width: 200px"
        v-model="searchForm.masterAccountUsernameList"
        placeholder="运营人"
        show-search
        option-filter-prop="children"
        allowClear
        :maxTagCount="2"
        mode="multiple"
        @change="getDataList(true)"
      >
        <a-select-option v-for="(item, index) in operatorList" :key="index" :value="item.operatorUsername">{{
          item.operatorName
        }}</a-select-option>
      </a-select>

      <a-input-group compact style="width: 330px">
        <a-select default-value="NOTE" style="width: 130px" v-model="searchForm.searchType" @change="getDataList(true)">
          <a-select-option value="NOTE"> 笔记标题 </a-select-option>
          <a-select-option value="AUTHOR"> 昵称/小红书号 </a-select-option>
          <a-select-option value="MEMBER"> 成员名称/编码 </a-select-option>
        </a-select>
        <a-input-search
          style="width: 200px"
          placeholder="搜索"
          v-model.trim="searchForm.searchKeyword"
          @search="getDataList(true)"
          allowClear
        ></a-input-search>
      </a-input-group>
    </div>

    <div style="display: flex; justify-content: space-between">
      <a-select
        style="width: 200px; justify-content: space-between"
        v-model="searchForm.groupId"
        placeholder="运营团队"
        show-search
        option-filter-prop="children"
        allowClear
        @change="getDataList(true)"
      >
        <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
          item.groupName
        }}</a-select-option>
      </a-select>
      <a-range-picker
        :placeholder="['发布开始', '发布结束']"
        :value="checkTimeRange"
        @change="onTimeChange"
        style="width: 200px"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        allowClear
      />
      <a-select
        style="width: 200px"
        v-model="searchForm.noteType"
        placeholder="笔记类型"
        show-search
        option-filter-prop="children"
        allowClear
        @change="getDataList(true)"
      >
        <a-select-option value="normal">图文</a-select-option>
        <a-select-option value="video">视频</a-select-option>
      </a-select>
      <a-select
        style="width: 200px"
        v-model="searchForm.publishChannel"
        placeholder="发布渠道"
        show-search
        option-filter-prop="children"
        allowClear
        @change="getDataList(true)"
      >
        <a-select-option v-for="(v, i) in channelList" :key="i" :value="v.value">{{ v.label }}</a-select-option>
      </a-select>

      <div style="display: flex; width: 330px; align-items: center; justify-content: space-between">
        <a-select
          style="width: 200px; margin-right: 10px"
          v-model="searchForm.operating"
          placeholder="账号运营状态"
          option-filter-prop="children"
          @change="getDataList(true)"
        >
          <a-select-option :value="true">正在运营</a-select-option>
          <a-select-option :value="false">停止运营</a-select-option>
        </a-select>
        <a-button style="width: 120px" type="primary" icon="file" :loading="exportLoading" @click="handleExport"
          >导出</a-button
        >
      </div>
    </div>

    <div class="table-container">
      <div class="table-line line-flex" v-show="selectedRowKeys.length">
        <div class="line-flex">
          <div>
            已选<span style="margin: 0 2px; color: #378ffd">{{ selectedRowKeys.length }}</span
            >项
          </div>
          <a-button type="link" @click="selectedRowKeys = []">清空</a-button>
          <!-- <a-popconfirm title="是否删除？" ok-text="确定" cancel-text="取消" @confirm="confirmMove">
            <a-button type="default" style="margin: 0 15px" :loading="batchLoading">批量删除</a-button>
          </a-popconfirm> -->
          <a-button type="default" v-if="!limited" :loading="batchLoading" @click="handleBatchPush()"
            >批量推送</a-button
          >
        </div>
      </div>
      <a-table
        v-tableSticky
        style="margin-top: 10px"
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.sourceId"
        :row-selection="rowSelection"
        :pagination="false"
        :scroll="{ x: 1800 }"
        @change="onTableChange"
      >
        <div slot="writingPreview" slot-scope="text, record">
          <div class="writingPreview">
            <div class="previewLeft" @click="openCheckWriting(record)">
              <img
                v-if="record.coverUrl"
                style="height: 100%; width: 100%; object-fit: cover"
                referrerpolicy="no-referrer"
                :src="record.coverUrl"
              />
            </div>
            <div class="previewRight">
              <div class="rightTitle" @click="openCheckWriting(record)" :title="record.noteTitle">
                {{ record.noteTitle }}
              </div>
              <div style="cursor: default" v-if="record.tagName">
                <span class="themeItem" :style="`background-color: ${record.tagColor || '#adafb3'}`">{{
                  record.tagName
                }}</span>
              </div>
              <div class="rightId">
                <a-tooltip>
                  <template slot="title"
                    >{{ record.authorName }}{{ record.authorCode ? `- ${record.authorCode}` : '' }}</template
                  >
                  <div style="display: flex">
                    <span class="logo"><img src="@/assets/icon/xhs_logo.png" alt="" /></span
                    ><span class="nickname-text" @click="handleJumpXHS(record)">{{ record.authorName }}</span>
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>

        <div slot="brandAndSeries" slot-scope="text, record">
          <div>{{ record.principalName || '-' }}</div>
          <div>{{ record.productName || '-' }}</div>
        </div>

        <div slot="publishChannel" slot-scope="text, record">
          <div>{{ getChannelName(record.publishChannel) }}</div>
        </div>

        <div slot="operation" slot-scope="text, record">
          <a @click="handleArticleJump(record)">查看原文</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item v-if="!limited && record.publishChannel !== 'NO_OPERATIONAL'">
                <a @click="handleAiRewrite(record.noteId)">提取文案</a>
              </a-menu-item>
              <a-menu-item v-if="!limited && record.publishChannel !== 'NO_OPERATIONAL'">
                <a @click="handleBatchPush(record.sourceId)">推送至选题库</a>
              </a-menu-item>
              <a-menu-item v-if="!limited && record.publishChannel === 'NO_OPERATIONAL'">
                <a @click="handleUpdatePublishChannel(record)">修改发布渠道</a>
              </a-menu-item>
              <a-menu-item>
                <a v-clipboard:copy="record.noteId" v-clipboard:success="onCopy" v-clipboard:error="onCopyError"
                  >复制笔记ID</a
                >
              </a-menu-item>
              <!-- <a-menu-item v-if="record.publishChannel !== 'NO_OPERATIONAL'">
                <a @click="handleDel(record.sourceId)">删除</a>
              </a-menu-item> -->
            </a-menu>
          </a-dropdown>
        </div>
      </a-table>

      <base-pagination
        :currentPage="pagination.current"
        :options="['30', '50', '100']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!-- 查看/编辑抽屉 -->
    <writtingDrawer
      v-if="showDrawer"
      :readOnly="true"
      :limited="limited"
      @closeDrawer="handleDrawerClose"
      :noteId="noteId"
      @updateList="updateList"
    />

    <!-- 批量推送 -->
    <BatchPushToTopicModal ref="BatchPushToTopicModal" @success="selectedRowKeys = []" />

    <!-- 修改发布渠道 -->
    <PublishEditionChannelModal ref="PublishEditionChannelModal" @success="updateList" />

    <!-- 定时推送弹窗 -->
    <a-modal width="600px" :visible="showPushModal" @close="handleCancel" @cancel="handleCancel" :zIndex="1">
      <div slot="title">
        <span style="margin-right: 10px">符合条件的笔记自动推送至选题库</span>
        <a-popover>
          <template slot="content">
            <p>符合任一条件即推送</p>
            <p>根据笔记原有的选题推送至选题库对应选题内</p>
          </template>
          <span style="cursor: pointer">
            <a-icon type="question-circle" />
          </span>
        </a-popover>
      </div>
      <div style="display: flex">
        <a-form-model
          style="width: 100%"
          :model="pushParams"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          :rules="rules"
          ref="form"
        >
          <a-form-model-item label="观看量" prop="viewAmount">
            <a-input type="number" placeholder="未设置" v-model="pushParams.viewAmount"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分享量" prop="shareAmount">
            <a-input type="number" placeholder="未设置" v-model="pushParams.shareAmount"></a-input>
          </a-form-model-item>
          <a-form-model-item label="互动量" prop="actAmount">
            <a-input type="number" placeholder="未设置" v-model="pushParams.actAmount"></a-input>
          </a-form-model-item>
          <a-form-model-item label="点赞量" prop="likeAmount">
            <a-input type="number" placeholder="未设置" v-model="pushParams.likeAmount"></a-input>
          </a-form-model-item>
          <a-form-model-item label="评价量" prop="commentAmount">
            <a-input type="number" placeholder="未设置" v-model="pushParams.commentAmount"></a-input>
          </a-form-model-item>
          <a-form-model-item label="收藏量" prop="collectCount">
            <a-input type="number" placeholder="未设置" v-model="pushParams.collectCount"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="btnLoading" @click="handleOk"> 确定 </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import moment from 'moment';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import debounce from 'lodash/debounce';
import BatchPushToTopicModal from '@/components/xhsAgencyTool/BatchPushToTopicModal.vue';
import PublishEditionChannelModal from '@/components/xhsAgencyTool/PublishEditionChannelModal.vue';
import { downloadExcel } from '@/utils';

export default {
  components: { writtingDrawer, TopicTreeInput, BatchPushToTopicModal, PublishEditionChannelModal },
  data() {
    const columns = [
      {
        title: '笔记',
        width: 280,
        fixed: 'left',
        scopedSlots: { customRender: 'writingPreview' },
      },
      {
        title: '车系',
        width: 110,
        align: 'center',
        dataIndex: 'carSeriesName',
        scopedSlots: { customRender: 'brandAndSeries' },
      },
      {
        title: '发布时间',
        width: 120,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'publishTime',
        customRender: (text) => {
          return text || 0;
        },
      },
      {
        title: '观看量',
        width: 100,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'readCnt',
        customRender: (text) => {
          return text || 0;
        },
      },
      {
        title: '互动量',
        align: 'center',
        width: 100,
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'interactCnt',
        customRender: (text) => {
          return text || 0;
        },
      },
      {
        title: '点赞',
        width: 100,
        align: 'center',
        sorter: true,
        dataIndex: 'likeCnt',
        customRender: (text) => {
          return text || 0;
        },
      },
      {
        title: '评论',
        width: 100,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'commentCnt',
        customRender: (text) => {
          return text || 0;
        },
      },
      {
        title: '收藏',
        width: 100,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'collectCnt',
        customRender: (text) => {
          return text || 0;
        },
      },
      {
        title: '分享',
        width: 100,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'shareCnt',
        customRender: (text) => {
          return text || 0;
        },
      },
      {
        title: '数据更新',
        width: 120,
        align: 'center',
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'crawlTime',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '添加时间',
        width: 120,
        align: 'center',
        sorter: true,
        defaultSortOrder: 'descend',
        sortDirections: ['descend', 'ascend', 'descend'],
        dataIndex: 'noteWritingTime',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '添加人',
        width: 120,
        align: 'center',
        dataIndex: 'noteCreator',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '成员名称',
        width: 120,
        align: 'center',
        dataIndex: 'memberName',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '发布渠道',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'publishChannel' },
      },
      {
        title: '笔记权限',
        width: 120,
        align: 'center',
        dataIndex: 'noteStatus',
        customRender: (text) => {
          let _text = '-';
          if (text == 1) _text = '公开';
          if (text == 0) _text = '仅自己可见';
          return _text;
        },
      },
      {
        title: '笔记类型',
        width: 120,
        align: 'center',
        dataIndex: 'noteType',
        customRender: (text) => {
          return text === 'normal' ? '图文' : '视频';
        },
      },
      {
        title: '删除状态',
        width: 120,
        align: 'center',
        dataIndex: 'noteIsDeleted',
        customRender: (text) => {
          let _text = '-';
          if (text == 1) _text = '已从小红书删除';
          if (text == 0) _text = '正常';
          return _text;
        },
      },
      {
        align: 'center',
        title: '操作',
        fixed: 'right',
        width: 160,
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const channelList = [
      {
        label: '风火轮',
        value: 'FENGHUOLUN',
      },
      {
        label: '分享链接',
        value: 'SHARE_PUBLISH_LINK',
      },
      {
        label: '百夫长',
        value: 'BAIFUZHANG',
      },
      {
        label: '非运营笔记',
        value: 'NO_OPERATIONAL',
      },
      {
        label: '其他',
        value: 'OTHER',
      },
    ];
    return {
      channelList,
      rules: {},
      pushParams: {},
      searchForm: {
        searchType: 'NOTE',
        principalId: undefined,
        productId: undefined,
        sort: undefined,
        sortField: undefined,
        masterAccountUsernameList: [],
        tagIdList: undefined,
        groupId: undefined,
        operating: true,
      },
      columns,
      selectedRowKeys: [],
      tableLoading: false,
      batchLoading: false,
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      operatorList: [],
      principalList: [],
      seriesList: [],
      seriesLoading: false,
      showDrawer: false,
      noteId: '',
      sortObj: {
        sortField: 'noteWritingTime',
        sort: 'desc',
      },
      showPushModal: false,
      btnLoading: false,

      noteTotal: 0,
      checkTimeRange: [],
      exportLoading: false,
      allGroupList: [],
      limited: false,
      allTeamList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    moment,
    async init() {
      const { limited, limitedName } = await this.checkLimit();
      this.limited = limited;
      await this.getAllGroupList();
      const find = this.allGroupList.find((v) => v.groupName === limitedName);
      if (this.limited && find?.id) {
        this.searchForm.groupId = find.id;
      }
      this.fetchPrincipalList();
      this.getOperatorTeamList();
      await this.getOperatorList();
      this.getDataList();
    },
    async getDataList(firstPage = false) {
      if (firstPage) this.pagination.current = 1;
      const publishTimeStart = this.checkTimeRange[0]
        ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`
        : null;
      const publishTimeEnd = this.checkTimeRange[1]
        ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`
        : null;
      const { sort, sortField } = this.sortObj;

      const params = {
        ...this.searchForm,
        publishTimeStart,
        publishTimeEnd,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        sort,
        sortField,
      };
      if (!params.tagIdList?.length) params.tagIdList = undefined;
      if (!params.publishChannel) params.publishChannel = 'ALL';
      this.selectedRowKeys = [];
      this.tableLoading = true;
      await this.handleRequest(
        'getNoteAnalysisList',
        (data) => {
          this.dataList =
            data.list?.map((v) => {
              if (!v.coverUrl && v.materialList?.length > 0) {
                v.coverUrl = v.materialList[0];
              }
              // oss图片
              if (v.coverUrl && (v.coverUrl.indexOf('afanticar') > -1 || v.coverUrl.indexOf('afanti') > -1)) {
                // 不处理视频截图
                if (v.coverUrl.indexOf('snapshot') == -1) {
                  if (v.coverUrl.indexOf('x-oss-process') > -1) {
                    v.coverUrl = v.coverUrl += `/resize,l_120,m_lfit`;
                  } else {
                    v.coverUrl = v.coverUrl += `?x-oss-process=image/resize,l_120,m_lfit`;
                  }
                }
              }
              if (!v.sourceId) v.sourceId = Math.random().toString(36).substring(2, 15);
              if (v.tagLevelThreeName) {
                v.tagName = v.tagLevelThreeName;
                v.tagColor = v.tagLevelThreeColor;
              } else if (v.tagLevelTwoName) {
                v.tagName = v.tagLevelTwoName;
                v.tagColor = v.tagLevelTwoColor;
              } else if (v.tagLevelOneName) {
                v.tagName = v.tagLevelOneName;
                v.tagColor = v.tagLevelOneColor;
              }
              return v;
            }) || [];
          this.noteTotal = data.total;
          this.pagination.total = data.total;
        },
        params
      ).finally(() => (this.tableLoading = false));
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    async fetchPrincipalList() {
      api.getBrandManageList({ size: 999, page: 1 }).then((res) => {
        this.principalList =
          res.data.list
            ?.filter((v) => v.status === 'NORMAL')
            ?.map((item) => {
              return { label: item.principalName, value: item.principalId };
            }) || [];
      });
    },
    getTypeList(principalIds, typeList) {
      this[typeList] = [];
      if (!principalIds) return;
      this.seriesLoading = true;
      api
        .getNewTypeList({ principalIds })
        .then((res) => {
          this[typeList] = res.data.map((val) => ({ id: val.id, name: val.name }));
        })
        .finally(() => (this.seriesLoading = false));
    },
    async handleSearchPrincipalChange(id) {
      this.getDataList(true);
      this.searchForm.productId = undefined;
      this.allTeamList = [];
      this.getOperatorTeamList(id);
      if (!id.length) return (this.seriesList = []);
      this.getTypeList(id, 'seriesList');
    },
    async getOperatorTeamList(principalId = undefined) {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999, principalId });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    onSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    onTableChange(pagination, filters, sorter) {
      const sort = sorter.order
        ? {
            sortField: sorter.columnKey,
            sort: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
          }
        : {
            sortField: '',
            sort: '',
          };
      this.sortObj = sort;
      this.getDataList();
    },
    openCheckWriting(value) {
      if (value.publishChannel === 'NO_OPERATIONAL') return this.$message.info('非运营笔记请点击「查看原文」打开');
      this.showDrawer = true;
      this.noteId = value.sourceId;
    },
    handleDrawerClose() {
      this.showDrawer = false;
      this.noteId = undefined;
    },
    updateList() {
      this.getDataList();
      this.handleDrawerClose();
    },
    handleDel(id) {
      this.$confirm({
        title: '提示',
        content: `删除文案不影响已发布的笔记，确定删除？`,
        onOk: () => {
          this.handleRequest(
            'handleNoteDel',
            () => {
              this.$message.success('操作成功');
              if (this.dataList.length == 1 && this.pagination.current > 1) this.pagination.current--;
              this.getDataList();
            },
            [id]
          );
        },
      });
    },
    getOperatorList() {
      return new Promise((resolve, reject) => {
        this.handleRequest(
          'getTeamMember',
          (data) => {
            this.operatorList = data.list;
            if (
              this.$router.app.user.username &&
              this.operatorList.findIndex((c) => c.operatorUsername === this.$router.app.user.username) !== -1
            ) {
              this.searchForm.masterAccountUsernameList.push(this.$router.app.user.username);
            }
            resolve();
          },
          { size: 999 }
        ).catch(() => reject());
      });
    },
    getAllGroupList() {
      return new Promise((resolve) => {
        const params = {
          pageNum: 1,
          pageSize: 100,
          groupScene: 6,
          xhsParam: null,
          groupName: null,
        };
        this.handleRequest(
          'getMediaGroupList',
          (data) => {
            this.allGroupList = data.list;
          },
          params
        ).finally(() => resolve());
      });
    },
    async onTimeChange(dates) {
      this.checkTimeRange = [dates[0], dates[1]];
      this.handleSearch();
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    // 移除管理
    confirmMove() {
      if (!this.selectedRowKeys.length) return this.$message.info('请至少选择一条数据');
      this.batchLoading = true;
      this.handleRequest(
        'handleNoteDel',
        () => {
          if (this.dataList.length == 1 && this.pagination.current > 1) this.pagination.current--;
          this.$message.success('删除成功');
          this.selectedRowKeys = [];
          this.getDataList();
        },
        this.selectedRowKeys
      ).finally(() => (this.batchLoading = false));
    },
    // 批量推送
    handleBatchPush(id) {
      let dataList = [];
      const selectIdList = id ? [id] : this.selectedRowKeys;
      this.dataList.forEach((val) => {
        for (let i of selectIdList) {
          if (val.sourceId == i) {
            dataList.push({
              id: val.sourceId,
              carSeriesId: val.carSeriesId,
              carSeriesName: val.productName,
              noteId: val.noteId,
              noteTitle: val.noteTitle,
              principalId: val.principalId,
              principalName: val.principalName,
            });
          }
        }
      });
      if (!dataList.length) return this.$message.info('请至少选择一条数据');
      this.$refs['BatchPushToTopicModal'].openModal({
        type: '笔记分析',
        dataList,
        createSource: 'NOTE_ANALYSE',
      });
    },
    /**
     * 修改发布渠道
     */
    handleUpdatePublishChannel(record) {
      this.$refs['PublishEditionChannelModal'].openModal(this.channelList, {
        noteId: record.noteId,
        publishChannel: record.publishChannel,
      });
    },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    handleCancel() {
      this.showPushModal = false;
    },
    handleOk() {
      this.handleCancel();
    },
    /**
     * 查看原文
     */
    handleArticleJump({ shareUrl, noteIsDeleted }) {
      if (noteIsDeleted == 1) return this.$message.info(`原文已删除`);
      if (!shareUrl) return this.$message.error(`笔记链接有误`);
      window.open(shareUrl, '_blank');
    },
    /**
     * Ai改写
     */
    handleAiRewrite(noteId) {
      if (!noteId) return this.$message.error(`笔记ID有误`);
      let routeData = this.$router.resolve({
        name: 'articleDetail',
        query: { noteId },
      });
      window.open(routeData.href, '_blank');
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      return new Promise(async (resolve, reject) => {
        const { code, data, message } = await api[APINAME](PARAMS);
        if (code === 200) {
          resolve(callbackFn(data));
        } else {
          this.$message.error(`${message}`);
          reject();
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async handleExport() {
      const publishTimeStart = this.checkTimeRange[0]
        ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`
        : null;
      const publishTimeEnd = this.checkTimeRange[1]
        ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`
        : null;
      const { sort, sortField } = this.sortObj;
      const params = {
        ...this.searchForm,
        publishTimeStart,
        publishTimeEnd,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        sort,
        sortField,
      };
      if (!params.tagIdList?.length) params.tagIdList = undefined;
      if (!params.publishChannel) params.publishChannel = 'ALL';
      this.exportLoading = true;
      const fileName = `笔记分析`;
      const res = await api.exportNoteAnalysis(params).finally(() => (this.exportLoading = false));
      downloadExcel(res, fileName);
    },
    filterOption(inputValue, option) {
      return option.componentOptions.children[0]?.text.indexOf(inputValue) > -1;
    },
    checkLimit() {
      return new Promise((resolve) => {
        let limited = false;
        const limitedRoleList = ['猛士运营'];
        let limitedName = '';
        this.$router.app.user.roles.forEach((v) => {
          if (limitedRoleList.indexOf(v.name) > -1) {
            limited = true;
            limitedName = v.name;
            return;
          }
        });
        resolve({ limited, limitedName });
      });
    },
    getChannelName(value) {
      const name = this.channelList.find((v) => v.value === value)?.label || '-';
      return name;
    },
    handleTagChange(value) {
      this.searchForm.tagIdList = value;
      this.getDataList(true);
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            publishChannel: record.publishChannel,
            disabled: record.publishChannel === 'NO_OPERATIONAL',
          },
        }),
      };
    },
  },
};
</script>

<style lang="less" scoped>
.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;

  .search-bar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .ant-form-item {
      width: 25%;
      flex-shrink: 0;
    }
  }

  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
}

.table-container {
  padding: 20px;
  background-color: #fff;

  .table-line {
    margin: 10px 0;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #e6f7ff;
    border: 1px solid #8fc9ff;
  }
}

.line-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      justify-content: start;
    }
  }
}

.nickname-text {
  margin-left: 5px;
  width: 160px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.amountItem {
  color: #464646;
  margin-right: 20px;
}

.writingPreview {
  display: flex;
  cursor: pointer;

  .previewLeft {
    margin-right: 5px;
    width: 60px;
    height: 80px;
    background-color: #80808033;
    background-size: cover;
    background-position: center;
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightTitle {
      width: 175px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .rightId {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ae7983;

      .rightIdL {
        width: 165px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.themeList {
  display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
}

.themeItem {
  margin: 2px 2px 0 0;
  padding: 1px 6px;
  border-radius: 5px;
  color: #fff;
}

.logo {
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

/deep/.ant-form-item {
  margin-bottom: 10px;
}

/deep/ .ant-table {
  font-size: 12px;
}
</style>
